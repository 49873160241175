.about {
background-color: rgb(195, 176, 145);
height: max-content!important;
}
.about .title h1 {
  color: #BA7741!important;
  text-align: center;
  font-weight: 900;
}
.about-her {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  overflow: hidden;
  color: #BA7741;
  padding: 20px 0px;
}
.about-her h1{
  font-weight: 900;
  line-height: 1.7;
}
.about-her p{
  line-height: 1.7;
}
.about-her h2 {
  color: #ffffff;
  line-height: 1.7;
  font-weight: 900;
}
.propert {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  gap: 30px;
}
.propp {
  background-color: #ffffff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  font-size: 20px;
  gap: 10px;
  color: rgb(37, 34, 34);
  border-radius: 30px;
  /* margin-top:120px; */
}
.propp .icon {
  color: rgb(0, 129, 242);
  font-size: 30px;
}
.propp:hover {
  transform: scale(1.05);
  transition: 0.4s;
  cursor: pointer;
}
.hashtag {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.hashtag img {
  width: 30%;
}
.about-img-middle{
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:0px;
  position: relative;
  /* height: 500px; */
}
.about-img-middle .button{
font-size: 30px;
background-color: #BA7741;
color: #ffffff;
padding: 10px;
border-radius: 10px;
cursor: pointer;
border: #BA7741 solid 1px;
margin: auto;
margin-top: 20px;
width: 60%;
text-align: center;
}
.about-img-middle .button:hover{
 background-color: transparent;
transition: 0.5s;
color: black;
}
.about-img-middle img{
  width: 100%;
}

.svg-animation text {
  color: rgb(255, 0, 0) !important;
  background-color: #ffffff !important;
  font-size: 70px !important;
}
