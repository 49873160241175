
/* start taha home */
.taha-home {
  height: 100vh;
  background-image: url('../../../assets/taha-assets/hero-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;
}


.taha-home .banner {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: space-around;
}

.taha-home .banner .image,
.taha-home .banner .text {
  width: 40%;
}

.taha-home .banner .text , 
.taha-home .banner .image {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

.taha-home .banner .text .box {
  width: 90%;
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px 0 24px 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.taha-home .banner .text .box h1 {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--text-color);
  position: relative;
  transition: all .3s ease-out 0s;
  z-index: 1;
  background: linear-gradient(#0278FF, #BD00FF);
  background-clip: text;
    -webkit-text-fill-color: transparent;
}

.taha-home .banner .text .box h1 img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  animation: bg 3s infinite;
  z-index: -1;
  transform: translateY(50%);
}

.taha-home .banner .text .box p {
  font-size: 2em;
  color: var(--text-color)
}

.taha-home .banner .image .box {
  height: 70%;
  /* background-color: red; */
  position: relative;
  z-index: 1;
}

.taha-home .banner .image .box .back-banner {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media(max-width: 767px) {
  .taha-home .banner {
      flex-direction: column;
      justify-content: space-between;
  }

  .taha-home .banner .image,
  .taha-home .banner .text {
      width: 100%;
  }

  .taha-home .banner .image .box {
      height: 100%;
  }

  .taha-home .banner .text .box h1,
  .taha-home .banner .text .box p {
      font-size: 1.5em;
  }

  .atom-one {
      width: 60px;
      height: 60px;
  }
}
.taha-home + section {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  margin-bottom: 25px;
}

.taha-home+section .back-circle-one,
.taha-home+section .back-circle-tow {
  position: absolute;
  z-index: -1;
}

.taha-home+section .back-circle-one {
  top: 0;
  right: 0;
}

.taha-home+section .back-circle-tow {
  bottom: 0;
  left: 0;
}

/* start courses section */
.taha-courses {
  padding: 3em 0;
  background-color:#C3B091;
  color: #BA7741;
  font-weight: 500;
}
.taha-courses .container .course-filter {
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.taha-courses h4 {
 font-weight: 900;
}
.taha-courses .container .course-filter li {
  padding: 10px;
  border-radius: 24px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 15px;
  background-color: #f9f6f7;
  color: #000504;
}
@media(max-width: 767px) {
  .taha-courses .container .row {
      justify-content: center;
  }
  .taha-courses .container .course-filter {
      flex-direction: column;
  }
  .taha-courses .container .course-filter li {
      width: 100%;
      margin: 0 0 10px 0;
  }
}
.taha-courses .container .course-filter li.active {
  background-color:#FB1A62 !important;
  color: #fff ;
}
.taha-courses .container .row > div{
      /* all: unset; */
      border-radius: 24px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      display: block;
      color: #000504!important;
}
.taha-courses .container .row > div .image {
  height: 200px;
}
.taha-courses .container .row > div .body {
  padding: 15px 10px 5px 10px;
}
.taha-courses .container .row > div .body .name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.taha-courses .container .row > div .body .name-price h6 {
  font-weight: bold;
}
.taha-courses .container .row > div .body .name-price p {
  font-size: 15px;
}
.taha-courses .container .row > div .body p {
  font-size: 12px;
}
.taha-courses .container .row > div .content {
  position: relative;
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.taha-courses .container .row > div .content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background-color: #ecf0f1;
}
.taha-courses .container .row > div .content p {
  margin: 0;
  font-size: 12px;
  color: var(--text-color);
  width: 45%;
}
.taha-courses .container .row > div .content p i {
  margin-left: 10px;
}
.taha-courses .container .row > div .course-footer {
  padding: 10px;
}
.taha-courses .container .row > div .course-footer a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 24px;
  background-color: #00BD99;
  color: #fff;
  transition: all 0.5s;
  font-weight: bold;
}
.taha-courses .container .row > div .course-footer a > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #00BD99;
  background-color: #fff;
  transition: all 0.5s;
}
.taha-courses .container .row > div .course-footer a > div i {
  color: #00BD99;
}
.taha-courses .container .row > div .course-footer a:hover {
  background-color: #fff;
  color: #00BD99;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.taha-courses .container .row > div .course-footer a:hover > div {
  background-color: #00BD99;
  color: #fff !important;
}
.taha-courses .container .row > div .course-footer a:hover > div i {
  color: #fff !important;
}

/* start courses */

/* start courses section */
.taha-courses {
  padding: 3em 0;
}
.taha-courses .container .course-filter {
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.taha-courses .container .course-filter li {
  padding: 10px;
  border-radius: 24px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 15px;
}
@media(max-width: 767px) {
  .taha-courses .container .row {
      justify-content: center;
  }
  .taha-courses .container .course-filter {
      flex-direction: column;
  }
  .taha-courses .container .course-filter li {
      width: 100%;
      margin: 0 0 10px 0;
  }
}
.taha-courses .container .course-filter li.active {
  background-color: #0278FF !important;
  color: #fff ;
}
.taha-courses .container .row > div{
      /* all: unset; */
      border-radius: 24px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      display: block;
}
.taha-courses .container .row > div .image {
  height: 200px;
}
.taha-courses .container .row > div .body {
  padding: 15px 10px 5px 10px;
}
.taha-courses .container .row > div .body .name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.taha-courses .container .row > div .body .name-price h6 {
  font-weight: bold;
}
.taha-courses .container .row > div .body .name-price p {
  font-size: 15px;
}
.taha-courses .container .row > div .body p {
  font-size: 12px;
}
.taha-courses .container .row > div .content {
  position: relative;
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.taha-courses .container .row > div .content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background-color: #ecf0f1;
}
.taha-courses .container .row > div .content p {
  margin: 0;
  font-size: 12px;
  color: var(--text-color);
  width: 45%;
}
.taha-courses .container .row > div .content p i {
  margin-left: 10px;
}
.taha-courses .container .row > div .course-footer {
  padding: 10px;
}
.taha-courses .container .row > div .course-footer a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 24px;
  background-color: #00BD99;
  color: #fff;
  transition: all 0.5s;
  font-weight: bold;
}
.taha-courses .container .row > div .course-footer a > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #00BD99;
  background-color: #fff;
  transition: all 0.5s;
}
.taha-courses .container .row > div .course-footer a > div i {
  color: #00BD99;
}
.taha-courses .container .row > div .course-footer a:hover {
  background-color: #fff;
  color: #00BD99;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.taha-courses .container .row > div .course-footer a:hover > div {
  background-color: #00BD99;
  color: #fff !important;
}
.taha-courses .container .row > div .course-footer a:hover > div i {
  color: #fff !important;
}
/* start best plateforms */
.best-plateforms .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.best-plateforms .content > a {
  display: block !important;
  width: calc(90% / 3);
  text-align: center;
  /* padding: 0 !important; */
}
.best-plateforms .content > a .image {
  height: 350px;
}
@media(max-width: 767px) {
  .best-plateforms .content {
    flex-direction: column;
  }
  .best-plateforms .content > a {
    margin-bottom: 15px;
    width: 100%
  }
}

/* start about section */
.taha-about .image {
  position: relative;
  z-index: 1;
  width: 40%;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 4em;
}

.taha-about .image .circle {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 80%;
}

.taha-about .image .light {
  position: absolute;
  z-index: 2;
  font-size: 5em;
}
.taha-about  .about-text {
  width: 100%;
  text-align: start;
}

.taha-about  .about-text h5.about-name {
  background: linear-gradient(#0278FF, #BD00FF);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin-bottom: 15px;
}

.taha-about .about-boxs {
  padding: 3em 0;
}

.taha-about .about-boxs > div {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-radius: 15px;
  background-color: #fff;
  color: var(--text-color);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  font-size: 1.3em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.5s;
}
.taha-about .about-boxs>div i {
  color: #0278FF;
  margin-left: 10px;
}

@font-face{
  font-family: alibon;
  src: url(../../../assets/alibon-font/AlibonRegular-OVa0O.ttf);
  }

svg.svg-animation {
	/* font-family: alibon; */
	width: 100%; height: 100%;
}
svg.svg-animation text {
	animation: stroke 7s infinite alternate;
	stroke-width: 0.5;
	stroke: #BA7741!important;
	font-size: 5em;
}
@keyframes stroke {
	0%   {
		fill:#C3B091; stroke: #BA7741;
		stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
	}
	70%  {fill:#C3B091; stroke:#BA7741; }
	80%  {fill:#C3B091; stroke: #BA7741; stroke-width: 2; }
	100% {
		fill:#C3B091; stroke: rgb(255, 255, 255);
		stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 2;
	}
}
.course-footer a{
  background-color:#FF8400!important;
  color:#FF8400;
}
.course-footer .fa-chevron-left{
 color: #FF8400!important;
}
.course-footer:hover a{
  background-color: #e6ac6f!important;
  color: #000504!important;
}
.course-footer:hover div{
  color: #000504!important;
  background-color: #000504!important;
}
/* responsive taha */
@media screen and (max-width:480px) {
  .home-content{
    height: max-content!important;
  }
  .home-content{
    display: flex;
  }
  .home{
    flex-direction: column-reverse!important;
    margin-top:30px!important;
    justify-content:center;
    align-items:center;
  }
  .home .home-right{
    width: 90%!important;
    text-align: center;
    margin: auto!important;
  }
  .home .home-right h1{
    line-height: 1.8;
  }.home .home-left{
    width: 100%!important;
    margin: auto!important;
    margin-bottom:60px!important;
    margin-right:40px!important;
  }
  .voca{
    width: 90%!important;
    margin: auto!important;
  }.props{
    flex-direction: column;
    gap: 50px!important;
  }
  .prop .props .proo{
    width: 90%!important;
    margin: auto!important;
  }.about-her{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%!important;
    margin: auto;
  }
  
  .about-her .propert{
    flex-direction: column;
  }.Best{
    flex-direction: column;
    gap: 30px!important;
  }
  .bests{
    width: 90%!important;
    margin: auto!important;
  }.mobile-menu-links {
    padding-top: 30px!important;
  }
  .hashtag{
    display: flex;
    flex-direction: column;
  }
  .hashtag img{
    width: 85%!important;
    margin: auto;
    object-fit: contain;
  }
  .svg-animation{
    margin: auto!important;
    font-size:10px!important;
  }
  .svg-animation text {
    color:white!important;
    background-color: #ffffff!important;
    font-size: 20px!important;

    font-size:70px!important;
}  .about-img-middle{
  width: 85%!important;
  margin: auto;
}
.about-img-middle .button{
  width: 90% !important;
  bottom:-35% !important;;
}
.about-img-middle .button img{
width:95%;
}
.cv img{
  width:90%!important;
  height: 100%;
  margin-bottom:20px;
}
.cv{
  margin-top:40px!important;
}.best .best-teacher{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  align-items: center;
  gap: 30px;
}
.best .best-teacher a{
  width: 90%!important;
}
}
@media screen and (min-width:481px) and (max-width:768px) {
  .home-content{
    height: max-content!important;
  }
  .home{
    flex-direction: column-reverse!important;
    margin-top:130px!important;
  }
  .home .home-right{
    width: 90%!important;
    text-align: center;
    margin: auto!important;
  }
  .home .home-right h1{
    line-height: 1.8;
  }.home .home-left{
    width: 85%!important;
    margin: auto!important;
    margin-right:100px!important;
  }
  .voca{
    width: 90%!important;
    margin: auto!important;
  }.props{
    flex-direction: row;
    gap: 50px!important;
    flex-wrap: wrap;
  }
  .prop .props .proo{
    width:45%!important;
    margin: auto!important;
  }.about-her{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%!important;
    margin: auto;
  }
  
  .about-her .propert{
    flex-direction: column;
  }.Best{
    flex-direction: row;
    gap: 30px!important;
    flex-wrap: wrap;
  }
  .bests{
    width: 45%!important;
    margin: auto!important;
    text-align: center!important;
    margin: auto!important;
  }.mobile-menu-links {
    padding-top: 30px!important;
  }  .hashtag{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .hashtag img{
    width:75%!important;
    margin: auto;
  }
  .about-img-middle{
    width: 85%!important;
    margin: auto;
  }
  .cv img{
    width:90%!important;
    height: 100%;
    margin-bottom:20px;
}
.cv{
  margin-top: 0px;
}.best .best-teacher{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content:space-around;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.best .best-teacher a{
  width: 45%!important;
}
}