.prop{
    overflow: hidden;
    padding: 20px;
    width: 100%;
    background-color: #C3B091;
  padding: 20px 10px;
}
.prop .props{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%!important;
    gap: 10px;
}.prop h1{
    color: #BA7741;
}
.prop .proo{
    width: 23%!important;
    margin: auto!important;
    display: flex;
    flex-direction: column;
    justify-content: center!important;
    align-items: center!important;
    text-align: center;
    background-color:#ffffff;
    color: rgb(0, 0, 0)!important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius:5px;
    padding: 10px;
    cursor: pointer;
    font-weight: 500;
    animation-name:heart;
    animation-duration: 4s;
    color: rgb(0, 0, 0)!important;
    animation-delay: 1s;
    animation-iteration-count: infinite;
}
.prop .proo:hover{
    background-color: #BA7741;
    transition: 0.5s;
    color: #ffffff!important;
  
}
.proo:hover  .ic{
    color: #fffbf7!important;
}
@keyframes heart {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.02);
    }
    100%{
        transform: scale(1);
    }
}
.prop .title{
    text-align: center;
    margin-bottom:20px;
    font-weight: 800;
}
.prop .props .proo .ic{
    font-size:50px;
    margin-bottom: 10px;
    color:#BA7741;
    z-index: 1000;
    transition: 0.5s;
}
.mark1{
    width: 20%!important;
    margin: auto;
    height: 5px;
    background-color:#BA7741;
    display: flex;
    border-radius: 10px;
    margin-bottom: 20px;
}
.mark2{
    width: 15%!important;
    margin: auto;
    height: 5px;
    background-color:#BA7741;
    display: flex;
    border-radius: 10px;
    margin-bottom:50px;
}
