.home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  /* background-image: url(../../../../assets/taha-assets/2.png); */
  background-size: cover;
}
.home {
  padding-top: 30px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items:center;
  overflow: hidden;
  position: relative;
  margin-top: 70px;
}
.home .home-left {
  width: 40%;
  height: 100%!important;
  margin-top: 30px;
}
.home .home-left .img1 {
  width: 80%;
  position: relative!important;
  z-index: 20;
}
.home .home-right {
  width:45%;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  gap:20px;
  z-index: 20;
}.home-right h1{
  color:#BA7741 ;
  font-weight: 900;
  font-size:40px;
}
.home-right h2{
  color:#C3B091 ;
  font-weight: 900;
  font-size:40px;
}
.home-right p{
  color:#000000;
  font-weight: 900;
  background-color: #C3B091;
  padding: 10px;
  border-radius: 10px;
}
.home .home-right img {
  width: 40%;
}
.letters{
  position: absolute;
  top:120px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  /* align-items: center; */
}
.letters p{
  background-color: #C3B091;
  padding: 5px;
  font-size: 20px;
  border-radius:10px;
  opacity: 0.8;
  height: max-content;
  color: aliceblue;
}
.letters .t{
  animation: t 5s 0s infinite;
}
.letters .h{
  animation: t 7s 0s infinite;
}
.letters .s{
  animation: t 7s 0.5s infinite;
}
.letters .l{
  animation: t 5s 0s infinite;
}
.letters .a{
  animation: t 7s 0s infinite;
}
.letters .ha{
  animation: t 5s 0s infinite;
}
@keyframes t {
  0%{
    margin-top:0px;
  }
  50%{
    margin-top:400px;
  }
  100%{
    margin-top: 0px;
  }
}
/* From Uiverse.io by vinodjangid07 */ 
.scroll{
  position: fixed;
  bottom: 20px ;
  right:20px;
  z-index: 200;
}
.scroll .button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(244, 140, 42);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(250, 130, 2, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

 
.scroll .svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

 
.scroll .svgIcon path {
  fill: white;
}

 
.scroll .button:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 96, 29);
  align-items: center;
}

 
.scroll .button:hover .svgIcon {
  /* width: 20px; */
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

 
.scroll .button::before {
  position: absolute;
  bottom: -20px;
  content: "الصعود الى الأعلى ";
  color: white;
  /* transition-duration: .3s; */
  font-size: 0px;
}

 
.scroll .button:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  /* transform: translateY(-30px); */
  transition-duration: 0.3s;
}
